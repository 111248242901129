import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { handleDjangoDataMutationRequest } from "../../utils/apiUtils";
import { toast } from "react-toastify";

export default function OtherInfoConfig({
  hotelId,
  otherConfigData,
  setOpenOtherInfoConfig,
  getOtherInfoConfig,
}) {
  const [propertyContact, setPropertyContact] = useState("");
  const [propertyStatusNotes, setPropertyStatusNotes] = useState("");
  const [dealOwner, setDealOwner] = useState("");
  const [isDigitalFranchise, setIsDigitalFranchise] = useState("");
  const [flexCollectNotes, setFlexCollectNotes] = useState("");
  const [sMSNotifications, setSMSNotifications] = useState("");
  const [whatsAppNotifications, setWhatsAppNotifications] = useState("");
  const [iDScannerIntegration, setIDScannerIntegration] = useState("");
  const [enableonGHA, setEnableonGHA] = useState("");
  const [enableonBOOKFLEXI, setEnableonBOOKFLEXI] = useState("");
  const [AccountType, setAccountType] = useState("");
  const [pgTransferOn, setPgTransferOn] = useState("");
  const [disableBanner, setDisableBanner] = useState("NO");

  const [loader, setLoader] = useState(false);

  // const updateDisableBanner = () => {
  //   const payload = {
  //     hotel_id: hotelId,
  //     disable_banner: disableBanner,
  //   };
  //   handleDjangoDataMutationRequest(
  //     "POST",
  //     `api/v2/metaAdmin/metaAdminUpdateDisableBanner/`,
  //     payload
  //   )
  //     .then(() => {})
  //     .catch(() => {});
  // };

  const saveOtherConfig = async () => {
    // updateDisableBanner();
    const payload = {
      id: otherConfigData?.id,
      property_contact: propertyContact,
      property_status_log: propertyStatusNotes,
      deal_owner: dealOwner,
      is_digitalfranchise: isDigitalFranchise,
      pss_comments: flexCollectNotes,
      booking_sms_notif: whatsAppNotifications,
      booking_whatsapp_notif: whatsAppNotifications,
      enable_id_scanner: iDScannerIntegration,
      enable_gha: enableonGHA,
      enable_bookflexi: enableonBOOKFLEXI,
      hotel_id: hotelId,
      pg_account_type: AccountType,
      pg_transfer_on: pgTransferOn,
      disable_banner: disableBanner,
    };
    setLoader(true);
    handleDjangoDataMutationRequest(
      "POST",
      `api/v2/metaAdmin/metaAdminAddInfo/`,
      payload
    )
      .then(() => {
        getOtherInfoConfig();
        toast.success("Other Info Config Saved Successfully");
        setOpenOtherInfoConfig(false);
        setLoader(false);
      })
      .catch(() => {
        toast.error("Error Saving Other Info Config");
        setLoader(false);
      });
  };

  useEffect(() => {
    if (otherConfigData) {
      setPropertyContact(otherConfigData.property_contact);
      setPropertyStatusNotes(otherConfigData.property_status_log);
      setDealOwner(otherConfigData.deal_owner);
      setIsDigitalFranchise(otherConfigData.is_digitalfranchise);
      setFlexCollectNotes(otherConfigData.pss_comments);
      setSMSNotifications(otherConfigData.booking_sms_notif);
      setWhatsAppNotifications(otherConfigData.booking_whatsapp_notif);
      setIDScannerIntegration(otherConfigData.enable_id_scanner);
      setEnableonGHA(otherConfigData.enable_gha);
      setEnableonBOOKFLEXI(otherConfigData.enable_bookflexi);
      setAccountType(otherConfigData.pg_account_type);
      setPgTransferOn(otherConfigData.pg_transfer_on);
      setDisableBanner(otherConfigData?.disable_banner);
    }
  }, [otherConfigData]);
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            label="Property Contact
        "
            id="fullWidth"
            variant="outlined"
            value={propertyContact}
            onChange={(e) => setPropertyContact(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            label="Property Status Notes
        "
            id="fullWidth"
            variant="outlined"
            value={propertyStatusNotes}
            onChange={(e) => setPropertyStatusNotes(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            label="Deal Owner"
            id="fullWidth"
            variant="outlined"
            value={dealOwner}
            onChange={(e) => setDealOwner(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Is Digital Franchise
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={isDigitalFranchise}
              label="Is Digital Franchise
            "
              onChange={(e) => setIsDigitalFranchise(e.target.value)}
            >
              <MenuItem value="YES">YES</MenuItem>
              <MenuItem value="NO">NO</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            label="Flex Collect Notes
        "
            id="fullWidth"
            variant="outlined"
            value={flexCollectNotes}
            onChange={(e) => setFlexCollectNotes(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              SMS Notifications
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sMSNotifications}
              label="SMS Notifications"
              onChange={(e) => setSMSNotifications(e.target.value)}
            >
              <MenuItem value="DISABLE">DISABLE</MenuItem>
              <MenuItem value="ENABLE">ENABLE</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              WhatsApp Notifications
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={whatsAppNotifications}
              label="WhatsApp Notifications
            "
              onChange={(e) => setWhatsAppNotifications(e.target.value)}
            >
              <MenuItem value="DISABLE">DISABLE</MenuItem>
              <MenuItem value="ENABLE">ENABLE</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              ID Scanner Integration
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={iDScannerIntegration}
              label="ID Scanner Integration
            "
              onChange={(e) => setIDScannerIntegration(e.target.value)}
            >
              <MenuItem value="DISABLE">DISABLE</MenuItem>
              <MenuItem value="ENABLE">ENABLE</MenuItem>
              <MenuItem value="AMBIRSCAN">AMBIRSCAN</MenuItem>
              <MenuItem value="IDGURU">IDGURU</MenuItem>
              <MenuItem value="DOCUMENTAI">DOCUMENTAI</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Enable on GHA</InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={enableonGHA}
              label="Enable on GHA
            "
              onChange={(e) => setEnableonGHA(e.target.value)}
            >
              <MenuItem value="DISABLED">DISABLED</MenuItem>
              <MenuItem value="ENABLED">ENABLED</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Enable on BOOKFLEXI
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={enableonBOOKFLEXI}
              label="Enable on BOOKFLEXI
            "
              onChange={(e) => setEnableonBOOKFLEXI(e.target.value)}
            >
              <MenuItem value="DISABLED">DISABLED</MenuItem>
              <MenuItem value="ENABLED">ENABLED</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Account type</InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={AccountType}
              label="Account type"
              onChange={(e) => setAccountType(e.target.value)}
            >
              <MenuItem value="DIRECT_CHARGE">Direct Charge</MenuItem>
              <MenuItem value="SEPARATE_CHARGE">Separate Charge</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              PG Transfer on
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={pgTransferOn}
              label="PG Transfer on"
              onChange={(e) => setPgTransferOn(e.target.value)}
            >
              <MenuItem value="IMMEDIATE">Immediately</MenuItem>
              <MenuItem value="BOOKING_CHECKIN">At booking checkin</MenuItem>
              <MenuItem value="BOOKING_CHECKOUT">At booking checkout</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Disable Banner
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={disableBanner}
              label=" Disable Banner"
              onChange={(e) => setDisableBanner(e.target.value)}
            >
              <MenuItem value="YES">YES</MenuItem>
              <MenuItem value="NO">NO</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="custom-button"
            fullWidth
            onClick={saveOtherConfig}
            disabled={loader}
          >
            Save {loader && <CircularProgress size={20} />}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
